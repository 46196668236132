import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./locale";
import axios from "axios";
import VueAxios from "vue-axios";
import { vue3Debounce } from 'vue-debounce'
import { getTheme } from "./core/theme";
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import "primevue/resources/themes/lara-light-blue/theme.css";
import AutoComplete from 'primevue/autocomplete';
import Menu from 'primevue/menu';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Chart from 'primevue/chart';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import BadgeDirective from 'primevue/badgedirective';
import OverlayPanel from 'primevue/overlaypanel';
import Tooltip from 'primevue/tooltip';

import '@/assets/scss/theme.scss';
import Keycloak from "keycloak-js";

import "./core/firebase";

import "jquery";

import "materialize-css";
import "materialize-css/dist/css/materialize.css";
import "material-icons/iconfont/material-icons.css";

import "v-calendar/dist/style.css";
import VCalendar from "v-calendar";
import { getUserData } from "./core/user";

let initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: process.env.VUE_APP_KEYCLOAK_ONLOAD,
};

let keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad as Keycloak.KeycloakOnLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      
      store.commit('SET_KEYCLOAK_INSTANCE', keycloak)
      let token = keycloak.token
      let refresh = keycloak.refreshToken
      store.commit('SET_TOKEN', token);
      store.commit('SET_REFRESH_TOKEN', refresh);

      (async () => {
        await getTheme();
        await getUserData(token);
      
        createApp(App)
          .use(VueAxios, axios)
          .use(VCalendar)
          .use(PrimeVue)
          .use(ConfirmationService)
          .use(i18n)
          .use(store)
          .use(router)
          .directive('debounce', vue3Debounce({ lock: true }))
          .directive('badge', BadgeDirective)
          .directive('tooltip', Tooltip)
          .component('AutoComplete', AutoComplete)
          .component('Menu', Menu)
          .component('InputText', InputText)
          .component('InputSwitch', InputSwitch)
          .component('Dropdown', Dropdown)
          .component('Chart', Chart)
          .component('ConfirmDialog', ConfirmDialog)
          .component('OverlayPanel', OverlayPanel)
          .mount("#app");
      })();
    }

    setInterval(() => {
      keycloak.updateToken(3600).then((refreshed) => {
        if (refreshed) {
          store.commit('SET_TOKEN', keycloak.token);
          store.commit('SET_REFRESH_TOKEN', keycloak.refreshToken);
        } else {
          console.log('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed!.exp! + keycloak.timeSkew! - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(() => {
        console.log('Failed to refresh token');
      });
    }, 1800000)
  })
  .catch(() => {
    console.log("Authenticated Failed");
  });
