<template>
  <div id="register-form">
    <div class="row">
      <div class="col s12 d-flex topbar">
        <router-link class="brand-logo" to="/" v-if="logoUrl">
          <img class="responsive-img" :src="logoUrl" alt="logo" />
        </router-link>
        <a v-else href="#!" class="brand-logo">Logo</a>
      </div>
      <div class="col s12 d-flex back">
        <i class="material-icons">arrow_back</i>
        <h5>{{ $t("register.back") }}</h5>
      </div>
      <div class="col s12">
        <div class="container">
          <h2 class="left-align">{{ $t("register.title") }}</h2>
          <p class="left-align">
            {{ $t("register.subtitle.text") }}
            <a href="#">{{ $t("register.subtitle.text") }}</a>
          </p>
          <div class="row">
            <form class="col s12" v-on:submit.prevent="register">
              <div class="row">
                <div class="file-field input-field d-flex">
                  <div class="camera-cont">
                    <img :src="require('@/assets/images/cameraicon.png')" alt="avatar" />
                  </div>
                  <input type="file" name="picture" id="picture" accept="image/*" v-on:change="selectImage($event)" />
                  <div class="file-path-wrapper">
                    <input class="file-path validate" type="text" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="names" type="text" class="validate" v-model="form.names" disabled />
                  <label for="names">{{ $t("register.fields[0]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="surnames" type="text" class="validate" v-model="form.surnames" disabled />
                  <label for="surnames">{{ $t("register.fields[1]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <select v-model="form.documentType">
                    <option value="CC">Cédula de ciudadanía</option>
                    <option value="CE">Cédula de extrangería</option>
                  </select>
                  <label>{{ $t("register.fields[2]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="documentNumber" type="text" class="validate" v-model="form.documentNumber"
                    v-on:keypress="isNumber($event)" />
                  <label for="documentNumber">{{
                    $t("register.fields[3]")
                  }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="medicalRecord" type="text" class="validate" v-model="form.medicalRecord"
                    v-on:keypress="isNumber($event)" />
                  <label for="medicalRecord">{{
                    $t("register.fields[4]")
                  }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s2">
                  <span class="p-float-label">
                    <Dropdown id="indicative" v-model="form.indicative" :options="indicatives" optionLabel="label_es" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
                    <label for="indicative">{{ $t("register.fields[5]") }}</label>
                  </span>
                </div>
                <div class="input-field col s10">
                  <input id="phone" type="tel" class="validate" v-model="form.phone" />
                  <label for="phone">{{ $t("register.fields[6]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col s12">
                  <div class="d-flex checkbox">
                    <label>
                      <input type="checkbox" class="filled-in" v-model="form.terms" />
                      <span></span>
                    </label>
                    <p>
                      {{ $t("register.terms.text") }}
                      <a href="#">{{ $t("register.terms.link") }}</a>
                    </p>
                  </div>
                </div>
                <div class="col s12">
                  <div class="d-flex checkbox">
                    <label>
                      <input type="checkbox" class="filled-in" v-model="form.policy" />
                      <span></span>
                    </label>
                    <p>
                      {{ $t("register.privacy.text") }}
                      <a href="#">{{ $t("register.privacy.link") }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <input type="submit" class="waves-effect waves-light btn btn-primary btn-rounded"
                    :value="$t('register.button')" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import store from "@/store";
  import countries from "@/data/countries.json"
  import Dropdown from 'primevue/dropdown';
  import { getUserData } from "@/core/user";

  export default({
    name: "RegisterForm",
    components: {
      Dropdown
    },
    data() {
      return {
        form: {
          names: "",
          surnames: "",
          documentType: "",
          documentNumber: "",
          medicalRecord: "",
          indicative: null,
          phone: "",
          terms: false,
          policy: false,
          picture: null,
        },
        countries: countries,
      };
    },
    computed: {
      logoUrl() {
        return store.getters.getCompanyLogo;
      },
      userToken() {
        return store.getters.getToken;
      },
      indicatives() {
        let a = []
        for (let i = 0; i < this.countries.countries.length; i++) {
          const e = this.countries.countries[i];
          let country = {
            code: e.code,
            dial_code: e.dial_code,
            name_en: e.name_en,
            name_es: e.name_es,
            label_es: e.dial_code + ' ' + e.name_es,
            label_en: e.dial_code + ' ' + e.name_en,
          }
          a.push(country)
        }
        return a
      }
    },
    methods: {
      isNumber(evt) {
        const keysAllowed = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          ".",
        ];
        const keyPressed = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault();
        }
      },
      isIndicative(evt) {
        const keysAllowed = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "+",
        ];
        const keyPressed = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault();
        }

        if (this.form.indicative === "" && keyPressed != "+") {
          evt.preventDefault();
        }
      },
      selectImage(e) {
        console.log(e.target.files[0]);
        this.form.picture = e.target.files[0];
      },
      async register() {
        const {
          names,
          surnames,
          documentType,
          documentNumber,
          medicalRecord,
          indicative,
          phone,
          terms,
          policy,
          picture,
        } = this.form;

        if (
          !names ||
          names.trim() == "" ||
          !surnames ||
          surnames.trim() == "" ||
          !documentType ||
          documentType.trim() == "" ||
          !documentNumber ||
          documentNumber.trim() == "" ||
          !medicalRecord ||
          medicalRecord.trim() == "" ||
          !indicative ||
          !phone ||
          phone.trim() == ""
        ) {
          return M.toast({
            html: this.$t('register.messages[0]'),
          });
        }

        if (!terms) {
          return M.toast({
            html: this.$t('register.messages[1]'),
          });
        }

        if (!policy) {
          return M.toast({
            html: this.$t('register.messages[2]'),
          });
        }

        const body = {
          Name: names,
          Lastname: surnames,
          Phone: indicative.dial_code + ' ' + phone,
          Document: {
            DocumentType: documentType,
            DocumentNumber: documentNumber,
          },
          MedicalNumber: medicalRecord,
          Country: "Col",
          PictureUrl: "",
        };

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        };

        try {
          await axios
            .post(
              process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/user/create-user",
              body, {
                headers: headers,
              }
            )
            .then(async (response) => {
              if (response.status === 200) {
                const newBody = {
                  Name: names,
                  Lastname: surnames,
                  Email: store.getters.getUser.Email,
                  Phone: indicative.dial_code + ' ' + phone,
                  Document: {
                    DocumentType: documentType,
                    DocumentNumber: documentNumber,
                  },
                  MedicalNumber: medicalRecord,
                  Country: "Col",
                  PictureUrl: "",
                  Role: "1",
                  HasAccess: true,
                };

                const params = {
                  userId: store.getters.getUser.Id
                }

                await axios.put(
                  process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/user",
                  newBody, {
                    headers,
                    params,
                  }
                ).then(async (response) => {
                  if(response.status == 200) {
                    M.toast({
                      html: this.$t('register.messages[3]'),
                    });
                    await getUserData(this.userToken);
                    this.$router.replace("/");
                  }
                });
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
      fillFields() {
        const {
          Name,
          LastName
        } = store.getters.getUser;
        this.form.names = Name.split(" ")[0];
        this.form.surnames = LastName;
        let input = document.getElementById("names");
        input.labels[0].classList.add("active");

        input = document.getElementById("surnames");
        input.labels[0].classList.add("active");
      },
    },
    mounted() {
      this.fillFields();
      M.AutoInit();
      var select = document.querySelectorAll("select");
      M.FormSelect.init(select);
    },
  });
</script>

<style scoped>
  .topbar {
    padding: 15px 33px;
    background-color: #ffffff;
  }

  #register-form {
    background-color: #fbfcff;
  }

  h2 {
    font-family: "Lato";
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    font-style: normal;
    margin: 65px 0 5px !important;
  }

  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #5c6470;
  }

  .input-field>label {
    font-weight: 400;
    font-size: 16px;
    color: #848f96;
  }

  .input-field>label.active {
    color: #909194 !important;
  }

  .btn {
    box-shadow: 0px 2px 15px rgba(158, 0, 255, 0.2);
    width: 420px;
    font-family: "Lato";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 45px;
  }

  form .row {
    margin-bottom: 0 !important;
  }

  .checkbox {
    margin: 5px 0;
  }

  .checkbox p {
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #454b55;
  }

  .file-path-wrapper {
    display: none !important;
  }

  .file-field {
    background-image: url("../../assets/images/PictureButton.png");
    background-color: #e1e2e6;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px;
    border-radius: 50px;
    align-items: flex-end;
    margin: 30px 0;
  }

  .camera-cont {
    background-color: #1b1d1f;
    height: 26px;
    width: 100%;
    mix-blend-mode: overlay;
  }
</style>