import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      logoUrl: null,
      token: null,
      refreshToken: null,
      user: null,
      keycloakInstance: null,
      color: null,
      notifications: [],
      app: null,
      company: null,
      plan: null,
      plans: null,
      country: null,
    }
  },
  mutations: {
    SET_LOGO: (state: any, payload) => state.logoUrl  = payload,
    SET_COLOR: (state: any, payload) => state.color  = payload,
    SET_TOKEN: (state: any, payload) => state.token  = payload,
    SET_REFRESH_TOKEN: (state: any, payload) => state.refreshToken  = payload,
    SET_USER: (state, payload) => state.user = payload,
    SET_COMPANY: (state, payload) => state.company = payload,
    SET_APP: (state, payload) => state.app = payload,
    SET_PLAN: (state, payload) => state.plan = payload,
    SET_PLANS: (state, payload) => state.plans = payload,
    SET_COUNTRY: (state, payload) => state.country = payload,
    SET_KEYCLOAK_INSTANCE: (state, payload) => state.keycloakInstance = payload,
    SET_NOTIFICATION: (state, payload) => state.notifications.push(payload),
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getCompanyLogo: state => state.logoUrl,
    getToken: state => state.token,
    getColor: state => state.color,
    getRefreshToken: state => state.refreshToken,
    getUser: state => state.user,
    getApp: state => state.app,
    getCompany: state => state.company,
    getPlan: state => state.plan,
    getPlans: state => state.plans,
    getCountry: state => state.country,
    getInstance: state => state.keycloakInstance,
    getNotifications: state => state.notifications,
    hasFormReady: state => state.user.HasFormReady,
    hasAccess: state => state.user.HasAccess,
    agendaSpecialties: state => state.user.AgendaQueueSpecialties,
  }
})
