import { getRoomGrant } from '@/services/twilio';
import { createLocalTracks, connect, RemoteParticipant, createLocalVideoTrack, LocalTrack, Room } from 'twilio-video';
import { VirtualBackgroundProcessor, isSupported } from '@twilio/video-processors';

enum CallType {
  Video = 1,
  Audio = 2
};


function createMediaContainer(participantIdentity: string) {
  const mediaContainer = document.createElement('div');
  mediaContainer.id = participantIdentity;
  mediaContainer.classList.add('media-container');
  mediaContainer.classList.add('cont-cam');

  return mediaContainer;
};

function getMediaContainer(participantIdentity: string) {
  return document.getElementById(participantIdentity)!;
};

const loadImage = (url: string) => 
  new Promise<any>((resolve) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(image);
  });


export async function joinRoom(userToken: string, roomName: string, type: number, selector: string) {
  const container = document.getElementById(selector)!;

  const token = await getRoomGrant(userToken, roomName);

  const hasVideo = type == CallType.Video;

  let localTracks: LocalTrack[] = [];

  try {
    localTracks = await createLocalTracks({
      audio: true,
      ...hasVideo && {
        video: true
      }
    });
  } catch (error) {
    console.error(error)
    return 'noTracks'
  }

  if(isSupported) {
    let bucket =  'https://storage.googleapis.com/' + process.env.VUE_APP_PUBLIC_BUCKET
    let file = process.env.VUE_APP_APP_ID === 'BOP20_COMPENSAR' ? '/callBackground.jpg' : '/callBackground.jpeg';
    let image = await loadImage(file);
    const bg = new VirtualBackgroundProcessor({
      assetsPath: bucket + '/twilio-assets',
      backgroundImage: image,
      maskBlurRadius: 5,
    })
    await bg.loadModel();
    
    localTracks.forEach(function(track) {
      if(track.kind == 'video') {
        track.addProcessor(bg)
      }
    });
  }

  let room: Room;

  try {
    room = await connect(token, {
      name: roomName,
      tracks: localTracks
    });
  } catch (error) {
    console.log(error)
    return null
  }

  localTracks.forEach(
    (track: any) => document.getElementById('localvideo')!.appendChild(track.attach())
  );

  console.log('Connected to the Room as LocalParticipant', room.localParticipant.identity);

  room.participants.forEach((participant) => {
    const participantIdentity = participant.identity;

    console.log('Participant', participantIdentity, 'is connected to the Room');

    const mediaContainer = createMediaContainer(participantIdentity);
    container.append(mediaContainer);
    
    participant.tracks.forEach(publication => {
      if (publication.track)
        if (publication.track.kind == 'audio' || publication.track.kind == 'video') {
          mediaContainer.appendChild(publication.track.attach());
        }
      
      publication.on('unsubscribed', () => {
        mediaContainer.querySelector('video')?.remove();
      })
    });
  
    participant.on('trackSubscribed', track => {
      if (track.kind == 'audio' || track.kind == 'video')
        mediaContainer.appendChild(track.attach());
    });

    participant.on('trackUnsubscribed', track => {
      if(track.kind == 'video') {
        mediaContainer.querySelector('video')?.remove();
      }
    })
  });

  room.on('participantConnected', (participant: RemoteParticipant) => {
    const participantIdentity = participant.identity;

    console.log('Participant', participantIdentity, 'connected');

    const mediaContainer = createMediaContainer(participantIdentity);
    container.append(mediaContainer);

    participant.tracks.forEach(
      (publication) => {
        if (publication.isSubscribed) {
          if (publication.track?.kind == 'audio' || publication.track?.kind == 'video')
            mediaContainer.append(publication.track.attach());
        }
      }
    );

    participant.on('trackSubscribed', function(track) {
      if (track.kind == 'video')
        mediaContainer.append(track.attach());
    });
  });

  room.on('participantDisconnected', function(participant: RemoteParticipant) {
    const participantIdentity = participant.identity;

    const mediaContainer = getMediaContainer(participantIdentity);
    console.log('Participant', participantIdentity, 'disconnected');
    mediaContainer.remove();
  });

  return room
};

export const previewCamera = async () => {
  let track = await createLocalVideoTrack()
  if(isSupported) {
    let bucket =  'https://storage.googleapis.com/' + process.env.VUE_APP_PUBLIC_BUCKET
    let image = await loadImage('/callBackground.jpeg');
    
    const bg = new VirtualBackgroundProcessor({
      assetsPath: bucket + '/twilio-assets',
      backgroundImage: image,
      maskBlurRadius: 5,
    })
    await bg.loadModel();
    
    track.addProcessor(bg)
  }
  const localMediaContainer = document.getElementById('local-media');
  localMediaContainer?.appendChild(track.attach());

  return track
}

export const disconnectCall = async (room) => {
  room.on('disconnected', room => {
    room.localParticipant.tracks.forEach(publication => {
      const attachedElements = publication.track.detach();
      attachedElements.forEach(element => element.remove());
    });
    document.getElementById('localvideo')!.innerHTML = '';
  });
  
  room.disconnect();
}

export const turnOnOffMic = (room, state) => {
  if(!state) {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
    });
  } else {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.enable();
    });
  }
}

export const turnOnOffCam = (room, state) => {
  const localMediaContainer = document.getElementById('localvideo');
  if(!state) {
    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.disable();
      localMediaContainer!.querySelector('video')!.style.display = "none";
    });
  } else {
    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.enable();
      localMediaContainer!.querySelector('video')!.style.display = "block";
    });
  }
}