
  import {
    defineComponent
  } from 'vue'
  import { database, auth } from './core/firebase';
  import { ref, get, onChildAdded, onChildChanged } from '@firebase/database'
  import { signInWithCustomToken, UserCredential } from 'firebase/auth'
  import store from './store'
  import axios from 'axios'

  export default defineComponent({
    async mounted() {
      await this.signIn();
      this.getNotifications();
      this.getAllNotifications();
    },
    computed: {
      hasFormReady() {
        return store.getters.hasFormReady;
      },
      userToken() {
        return store.getters.getToken;
      },
      notifications() {
        return store.getters.getNotifications;
      },
      plans() {
        return store.getters.getPlans;
      }
    },
    methods: {
      async signIn() {
        let currentUser = auth.currentUser
        if (!currentUser) {
          const headers = {
            'Authorization': `Bearer ${this.userToken}`,
            'appid': process.env.VUE_APP_APP_ID,
            'companyid': process.env.VUE_APP_COMPANY_ID,
          };
  
          let response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/v1/customlogin', {
            headers,
            params: {
              doctor: true
            }
          })
  
          await signInWithCustomToken(auth, response.data).then((userCredential: UserCredential) => {
            console.log('login')
          }).catch((error) => {
            console.log(error)
          })
        }
      },
      getNewNotifications() {
        let notiRef = ref(database, 'notifications/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/Doctors/' + store.getters.getUser.Id);
        onChildAdded(notiRef, (snap) => {
          let noti = this.transformNoti(snap.val(), snap.key)
          let index = this.notifications.some(function (el) { return el.data.notificationId == noti.data.notificationId; })
          if (!index)
            store.commit('SET_NOTIFICATION', noti)
        }); 
      },
      async getAllNotifications() {
        let notiRef = ref(database, 'notification');
        let snapshot = await get(notiRef);

        if(!snapshot.exists()) return;

        let notifications = snapshot.val();

        for (const key in notifications) {
          const noti = notifications[key];
          if(noti.DoctorId === store.getters.getUser.Id) {
            for (let i = 0; i < noti.Notifications.length; i++) {
              const e = noti.Notifications[i];
              e.data.appointmentId = noti.Id;
              if (e.properties.fromPatient) {
                store.commit('SET_NOTIFICATION', this.transformNoti(e, ''))
              }
            }
          }
        }
      },
      getNotifications() {
        let notiRef = ref(database, 'notification');
        onChildChanged(notiRef, (snap) => {
          let noti = snap.val();
          if(noti.DoctorId === store.getters.getUser.Id) {
            for (let i = 0; i < noti.Notifications.length; i++) {
              const e = noti.Notifications[i];
              e.data.appointmentId = noti.Id;
              if (e.properties.fromPatient) {
                let index = this.notifications.some(function (el) { return el.data.appointmentId == e.data.appointmentId && el.data.notificationCode == e.data.notificationCode; })
                if (!index) {
                  store.commit('SET_NOTIFICATION', this.transformNoti(e, ''))
                } 
              }
            }
          }
        }); 
      },
      transformNoti(value, id) {
        let notification = value
        let data = notification.data;
        if(id !== '') data.notificationId = id;

        if(data.notificationCode == "PT001") {
          data.title = this.$t('notifications.onWaitingRoom.title')
          data.message = this.$t('notifications.onWaitingRoom.text')
        }

        if(data.notificationCode == "VD001") {
          data.title = this.$t('notifications.onCall.title')
          data.message = this.$t('notifications.onCall.text')
        }

        if(data.notificationCode == "DT002") {
          data.title = this.$t('notifications.onFinishedCall.title')
          data.message = this.$t('notifications.onFinishedCall.text')
        }

        if(data.notificationCode == "FL001") {
          data.title = this.$t('notifications.onFile.title')
          data.message = this.$t('notifications.onFile.text')
        }

        let a = {
          data: notification.data,
          date: new Date(notification.timestamp),
          properties: notification.properties
        }

        return a
      }
    },
  })
