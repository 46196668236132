import axios from "axios";

export async function getRoomGrant(userToken: string, roomName: string): Promise<string> {
  const headers = {
    'Authorization': `Bearer ${userToken}`,
    'appid': process.env.VUE_APP_APP_ID,
    'companyid': process.env.VUE_APP_COMPANY_ID,
    'Content-Type': 'application/json'
  };

  let response = {} as any

  try {
    response = await axios.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/v1/twillio/videocallauthorization', roomName, {
      headers,
    }) 
  } catch (error) {
    console.log(error)
  }

  return response.data
};