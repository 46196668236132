<template>
  <div id="topbar">
    <ul id="dropdown1" class="dropdown-content nested">
      <li @click="logOut"><a> {{ $t('topbar.items[1]') }} </a></li>
    </ul>
    <ul id="dropdown2" class="dropdown-content">
      <li>
        <a @click="changeLanguage('en')">EN</a>
      </li>
      <li>
        <a @click="changeLanguage('es')">ES</a>
      </li>
      <li>
        <a @click="changeLanguage('pr')">PR</a>
      </li>
    </ul>
    <div class="navbar-fixed">
      <nav>
        <div class="nav-wrapper">
          <router-link class="brand-logo" to="/" v-if="logoUrl">
            <img class="responsive-img" :src="logoUrl" alt="logo">
          </router-link>
          <a v-else href="#!" class="brand-logo">Logo</a>
          <ul class="right hide-on-med-and-down">
            <li>
              <a v-badge="badge" @click="toggle" :class="{ 'not-badge': badge <= 0 }"><i class="material-icons-outlined">notifications</i></a>
            </li>
            <li class="locale-li">
              <a id="trigger2" class="dropdown-trigger" href="#" data-target="dropdown2">
                {{ $t('topbar.items[0]') }}: {{ locale }}
                <i class="material-icons right">arrow_drop_down</i>
              </a>
            </li>
            <li>
              <img class="circle responsive-img" :src="require('@/assets/images/' + avatar)" alt="user-avatar" />
            </li>
            <li>
              <a id="trigger1" class="dropdown-trigger" href="#" data-target="dropdown1">
                {{ userData.Name }} {{ userData.LastName }}
                <i class="material-icons right">arrow_drop_down</i></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <OverlayPanel ref="op" :dismissable="true">
      <div class="noti-header">
        <h1>Notificaciones ({{ notifications.length }}) </h1>
      </div>
      <div class="noti-content">
        <div class="notification" v-for="(noti, index) in notifications" :key="index">
          <p class="left-align date"> {{ convertDate(noti.date) }} </p>
          <h5 class="left-align title"> {{ noti.data.title }} </h5>
          <p class="left-align text"> {{ noti.data.message }} </p>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
  import M from "materialize-css";
  import store from "@/store";
  import { signOut } from 'firebase/auth'
  import { auth } from '../../../core/firebase';

  export default({
    name: "TopBar",
    data() {
      return {
        avatar: "AvatarIcon.png",
        isOpen: false,
        badge: 0,
      };
    },
    computed: {
      locale() {
        return this.$i18n.locale.toUpperCase()
      },
      logoUrl() {
        return store.getters.getCompanyLogo
      },
      userData() {
        return store.getters.getUser
      },
      idToken() {
        return store.getters.getInstance.idToken
      },
      notifications() {
        return store.getters.getNotifications.filter((o) => o.properties.showNotification).sort((a, b) => { return b.date - a.date })
      },
      notiLength() {
        return store.getters.getNotifications.filter((o) => o.properties.showNotification).length
      }
    },
    watch: {
      notiLength() {
        this.badge = this.badge + 1
      }
    },
    mounted() {
      M.AutoInit();
      setTimeout(() => {
        var dropdown = document.querySelectorAll(".dropdown-trigger");
        var dropdownOptions = {
          closeOnClick: false,
          hover: true,
          coverTrigger: false,
        };
        M.Dropdown.init(dropdown, dropdownOptions);
      }, 100);
    },
    methods: {
      changeLanguage(lang) {
        this.$i18n.locale = lang;
      },
      logOut() {
        signOut(auth);
        const encodeRedirectUri = window.location.href.split('#')[0].replaceAll(':', '%3A').replaceAll('/', '%2F')

        window.location.href = process.env.VUE_APP_KEYCLOAK_URL + '/realms/' + process.env.VUE_APP_KEYCLOAK_REALM + '/protocol/openid-connect/logout?post_logout_redirect_uri='+ encodeRedirectUri + '&id_token_hint=' + this.idToken
      },
      toggle(event) {
        this.badge = 0
        this.$refs.op.toggle(event);
      },
      convertDate(d) {
        return this.addZero((d.getMonth()+1)) + '/' + this.addZero(d.getDate()) + ' - ' + this.addZero(d.getHours()) + ':' + this.addZero(d.getMinutes())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
    }
  });
</script>

<style scoped>
  #topbar {
    border: 1px solid #E4E7EC;
  }

  .navbar-fixed {
    top: -1px !important;
  }

  nav {
    background-color: white;
    box-shadow: none;
  }

  nav img {
    vertical-align: middle;
  }

  li img {
    max-width: 32px;
    max-height: 32px;
  }

  nav a {
    color: #242a33 !important;
  }

  nav ul a {
    font-weight: 500;
    font-size: 16px;
  }

  nav .brand-logo {
    font-family: "Poppins";
    font-weight: 600;
    left: 90px;
    top: 5px;
  }

  .dropdown-content.nested {
    overflow-y: visible;
  }

  .dropdown-content .dropdown-content {
    margin-left: 100%;
  }

  .locale-li {
    margin-right: 15px;
  }

  .not-badge:deep(.p-badge) {
    display: none;
  }

  @media (max-width: 768px) {
    nav .brand-logo {
      left: 20%;
    }
  }

  
</style>